export function questionByType(type, locale = "lt") {
  try {
    return require(`./${type}/${locale}/questions.json`);
  } catch (e) {
    console.error(e);
  }

  return null;
}

export const componentByType = (type) => {
  switch (type) {
    case "surveillance":
      return () =>
        import("@/components/Questionnaire/ProductSteps/SurveillanceQuestions");
    case "marketing":
      return () =>
        import("@/components/Questionnaire/ProductSteps/MarketingQuestions");
    default:
      return () =>
        import("@/components/Questionnaire/ProductSteps/GeneralQuestions");
  }
};

export const dataUsagePurposes = [
  { name: "Įstatymo pagrindu (pvz. buhalterija)", slug: "law_purpose" },
  {
    name: "Sutarties pagrindu (pvz. paslaugų teikimas)",
    slug: "contract_purpose",
  },
  { name: "Sutikimo pagrindu (pvz. Rinkodara)", slug: "agree_purpose" },
  {
    name: "Statistikos, istoriniais ar mokslinio tyrimo tikslais įstatymų numatytais atvejais",
    slug: "statistics_purpose",
  },
];

export const nextQuestionType = (type = "") => {
  switch (type) {
    case "company_info":
      return "general_info";
    case "general_info":
      return "data_usage_purpose";
    case "data_usage_purpose":
      return "data_collection";
    case "data_collection":
      return "surveillance";
    case "surveillance":
      return "marketing";
    case "marketing":
      //   return "it";
      // case "it":
      return null;
    case "":
    default:
      return "company_info";
  }
};
